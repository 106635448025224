import React from 'react'
import { Accordion, useAccordionButton } from 'react-bootstrap'
import Markdown from 'markdown-to-jsx'

interface Props {
  key?: string
  linkText: string
  linkClass?: string
  bodyClass?: string
  children?: React.ReactElement
}

const CustomToggle = ({ children, eventKey, linkClass }) => {
  const decoratedOnClick = useAccordionButton(eventKey)

  return (
    <button type='button' className={linkClass} onClick={decoratedOnClick}>
      {children}
    </button>
  )
}

const CollapseLink: React.FC<Props> = ({
  children,
  key = '1',
  linkText,
  linkClass = '',
  bodyClass = '',
}) => {
  return (
    <Accordion defaultActiveKey='0'>
      <CustomToggle eventKey={key} linkClass={'btn btn-link p-0 ' + linkClass}>
        <Markdown>{linkText}</Markdown>
      </CustomToggle>

      <Accordion.Collapse eventKey={key} className={bodyClass}>
        <>{children}</>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default CollapseLink
