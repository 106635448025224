import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useTranslation } from '../../services/translation'
import { Alert, Spinner } from 'react-bootstrap'
import Button from '../Button'
import PinInput from '../PinInput'
import { useAuth } from '../../contexts/auth'
import { TransWithComponents } from '../TransWithComponents'

const RESEND_EMAIL = gql`
  mutation resendEmail($sendConfirmationLink: Boolean!) {
    resendEmail(sendConfirmationLink: $sendConfirmationLink)
  }
`

const CONFIRM_EMAIL = gql`
  mutation confirmEmail($email: String!, $numericCode: String!) {
    confirmEmail(email: $email, numericCode: $numericCode)
  }
`

const EmailConfirmForm = ({ sendConfirmationLink, onSuccess }) => {
  const { user, refreshToken } = useAuth()
  const [code, setCode] = useState('')
  const [confirmingCode, setConfirmingCode] = useState(false)
  const [confirmEmail, { error: errorConfirm }] = useMutation(CONFIRM_EMAIL)
  const handlePinComplete = async code => {
    try {
      setConfirmingCode(true)
      await confirmEmail({
        variables: {
          email: user.email,
          numericCode: code,
        },
      })
      await refreshToken()
      onSuccess()
    } catch (error) {
      setConfirmingCode(false)
      setCode('')
    }
  }
  const [resendEmail, { loading, error, data }] = useMutation(RESEND_EMAIL)
  const onClickResend = () => {
    resendEmail({
      variables: {
        sendConfirmationLink,
      },
    })
  }
  const { t } = useTranslation()
  return (
    <div className='text-center'>
      {errorConfirm && (
        <Alert variant='danger'>
          {errorConfirm.networkError
            ? t('confirmaemail.failed')
            : errorConfirm.graphQLErrors.map(({ state, message }) =>
                !state
                  ? message
                  : Object.entries(state).map(entry => entry[1][0])
              )}
        </Alert>
      )}
      <p>
        <TransWithComponents
          i18nKey='translation:confirmaemail.txt'
          components={[<strong key={0} />]}
          values={{
            email: user.email,
          }}
        />
      </p>
      <PinInput
        value={code}
        onChange={newCode => setCode(newCode)}
        pinLength={6}
        autoFocus
        onComplete={handlePinComplete}
        disabled={confirmingCode}
      />
      {confirmingCode && (
        <Alert variant='info'>
          <div className='text-center mb-2'>
            <Spinner animation='border' />
          </div>
          {t('confirmaemail.confirming')}
        </Alert>
      )}
      <p>
        <small>{t('confirmaemail.txt2')}</small>
      </p>
      {error && (
        <Alert variant='danger'>
          {error.networkError
            ? t('confirmaemail.failed')
            : error.graphQLErrors.map(({ state, message }) =>
                !state
                  ? message
                  : Object.entries(state).map(entry => entry[1][0])
              )}
        </Alert>
      )}
      {data && <Alert variant='success'>{t('confirmaemail.success')}</Alert>}
      <Button
        label={t('confirmaemail.btnreenviar')}
        onClick={onClickResend}
        loading={loading}
        disabled={loading}
        className='btn-paralel btn-full mx-auto'
      />
    </div>
  )
}

export default EmailConfirmForm
