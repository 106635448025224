import React, { useEffect, useRef, useState } from 'react'

const getNextValue = (value, eventValue) => {
  let nextValue = eventValue
  if (value.length > 0) {
    if (value[0] === eventValue.charAt(0)) {
      nextValue = eventValue.charAt(1)
    } else if (value[0] === eventValue.charAt(1)) {
      nextValue = eventValue.charAt(0)
    }
  }
  return nextValue
}

const validateNumber = value => {
  const NUMERIC_REGEX = /^[0-9]+$/
  return NUMERIC_REGEX.test(value)
}

const PinInput = ({
  value,
  onChange,
  pinLength,
  autoFocus,
  onComplete,
  disabled,
}) => {
  const inputRef = useRef([])
  const [values, setValues] = useState([])
  useEffect(() => {
    const newValues = value.split('')
    for (let i = newValues.length; i < pinLength; i++) {
      newValues[i] = ''
    }
    setValues(newValues)
  }, [value, pinLength])
  const setValue = (value, index) => {
    const newValues = [...values]
    newValues[index] = value
    setValues(newValues)
    onChange(newValues.join(''))
    const isComplete =
      value !== '' &&
      newValues.length === pinLength &&
      newValues.every(inputValue => inputValue != null && inputValue !== '')
    if (isComplete) {
      onComplete(newValues.join(''))
    } else {
      const nextIndex = index + 1
      if (value !== '' && inputRef.current[nextIndex]) {
        inputRef.current[nextIndex].focus()
      }
    }
  }
  const handleChange = (e, index) => {
    const eventValue = e.target.value
    const currentValue = values[index]
    const nextValue = getNextValue(currentValue, eventValue)
    if (eventValue.length > 2) {
      if (validateNumber(eventValue)) {
        const newValues = eventValue
          .split('')
          .filter((_, index) => index < pinLength)
        if (newValues.length < pinLength) {
          for (let i = newValues.length - 1; i < pinLength; i++) {
            newValues[i] = ''
          }
        }
        setValues(newValues)
        onChange(newValues.join(''))
        const isComplete =
          newValues.length === pinLength &&
          newValues.every(inputValue => inputValue != null && inputValue !== '')
        if (isComplete) {
          onComplete(newValues.join(''))
        }
      }
    } else {
      if (nextValue === '' || validateNumber(nextValue)) {
        setValue(nextValue.charAt(0), index)
      }
    }
  }
  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace') {
      const prevIndex = index - 1
      if (event.target.value === '' && inputRef.current[prevIndex]) {
        inputRef.current[prevIndex].focus()
      }
    }
  }
  return (
    <div className='d-flex justify-content-center mb-3'>
      {values.map((value, index) => (
        <input
          key={index}
          type='tel'
          value={value}
          onChange={e => handleChange(e, index)}
          onKeyDown={e => handleKeyDown(e, index)}
          autoFocus={autoFocus && index === 0}
          ref={el => (inputRef.current[index] = el)}
          className='form-control mx-1 text-center'
          style={{
            width: 40,
          }}
          disabled={disabled}
        />
      ))}
    </div>
  )
}

export default PinInput
