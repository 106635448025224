import React, { useState } from 'react'
import ModalConfirm2 from '../ModalConfirm2/index'
import { useTranslation } from '../../services/translation'
import CollapseLink from '../CollapseLink'
import LoginForm from '../LoginForm'
import RegisterForm from '../RegisterForm'
import EmailConfirmForm from '../EmailConfirmForm'

const ModalNotLoggedIn = ({ show, handleClose, onSuccess }) => {
  const { t } = useTranslation()
  const [screen, setScreen] = useState(0)
  const handleClickPresignup = e => {
    e.preventDefault()
    setScreen(1)
  }
  const handleLoginSuccess = (socialLogin, result) => {
    if (!socialLogin && !result.login.user.emailConfirmed) {
      setScreen(2)
    } else {
      onSuccess()
    }
  }
  const onClickBack = e => {
    e.preventDefault()
    setScreen(0)
  }
  return (
    <ModalConfirm2
      show={show}
      handleClose={handleClose}
      title={t('modal.naologado.tit')}
      size='xs'
    >
      {screen === 0 && (
        <>
          <LoginForm
            onClickPresignup={handleClickPresignup}
            onSuccess={handleLoginSuccess}
          />
          <hr />
          <CollapseLink
            linkText={`(${t('modal.naologado.link')})`}
            bodyClass='fs-xsmall'
          >
            {t('modal.naologado.txt2')}
          </CollapseLink>
        </>
      )}
      {screen === 1 && (
        <>
          <a className='mb-3 d-inline-block' href='#' onClick={onClickBack}>
            &lt; {t('modal.naologado.backLogin')}
          </a>
          <RegisterForm
            onSuccess={handleLoginSuccess}
            sendConfirmationLink={false}
          />
        </>
      )}
      {screen === 2 && (
        <EmailConfirmForm sendConfirmationLink={false} onSuccess={onSuccess} />
      )}
    </ModalConfirm2>
  )
}

export default ModalNotLoggedIn
