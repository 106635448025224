import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { useTranslation } from '../../services/translation'
import { Form, Alert, Col, Row } from 'react-bootstrap'
import PassWithEye from '../PassWithEye'
import Button from '../Button'
import Link from 'next/link'
import ErrorFocus from '../ErrorFocus'
import ButtonFacebook from '../ButtonFacebook'
import ButtonGoogle from '../ButtonGoogle'
import { useAuth } from '../../contexts/auth'
import { useBalance } from '../../contexts/balance'
import { deviceType, browserName } from 'react-device-detect'
import { useLocales } from '../../contexts/locales'

const LoginForm = ({ onSuccess, onClickPresignup }) => {
  const [generalErrors, setGeneralErrors] = useState('')
  const { t } = useTranslation()
  const { currency } = useLocales()
  const { signIn, signInSocial } = useAuth()
  const { setBalance } = useBalance()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('forms.emailinvalido'))
        .required(t('forms.emailvali')),
      password: Yup.string().required(t('forms.senhavali')),
    }),
    onSubmit: async (values, { setFieldError }) => {
      try {
        const { email, password, remember } = values
        setGeneralErrors('')
        const result = await signIn(email, password, remember)
        const {
          balance,
          balancePending,
          balanceChargeback,
          balanceCollection,
        } = result.login.user
        setBalance(
          balance,
          balancePending,
          balanceChargeback,
          balanceCollection
        )
        onSuccess(false, result)
      } catch ({ networkError, graphQLErrors }) {
        let gError = ''
        if (networkError || (!networkError && !graphQLErrors))
          gError = t('login.failed')
        else {
          if (graphQLErrors.length) {
            graphQLErrors.map(({ state }) => {
              Object.entries(state).forEach(entry => {
                if (entry[0] === '') gError = entry[1][0]
                else setFieldError(entry[0], entry[1][0])
              })
            })
          }
        }
        setGeneralErrors(gError)
      }
    },
  })
  const doSocialLogin = (socialNetwork, userId, name, email, token) => {
    signInSocial(socialNetwork, userId, name, email, token, currency)
      .then(result => {
        const {
          balance,
          balancePending,
          balanceChargeback,
          balanceCollection,
        } = result.loginSocial.user
        setBalance(
          balance,
          balancePending,
          balanceChargeback,
          balanceCollection
        )
        onSuccess(true, result)
      })
      .catch(({ networkError, graphQLErrors }) => {
        let gError = ''
        if (networkError || (!networkError && !graphQLErrors))
          gError = t('login.failed')
        else {
          if (graphQLErrors.length) {
            graphQLErrors.map(({ state }) => {
              Object.entries(state).forEach(entry => {
                gError = entry[1][0]
              })
            })
          }
        }
        setGeneralErrors(gError)
      })
  }
  const responseFacebook = response => {
    if (!response.accessToken) return false
    doSocialLogin(
      'facebook',
      response.userID,
      response.name,
      response.email,
      response.accessToken
    )
  }
  const responseGoogle = response => {
    if (!response.profileObj) return false
    doSocialLogin(
      'google',
      response.googleId,
      response.profileObj.name,
      response.profileObj.email,
      response.tokenId
    )
  }
  return (
    <>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Row>
          <Form.Group as={Col} className='mb-3' controlId='formEmail'>
            <Form.Label>{t('forms.email')}</Form.Label>
            <Form.Control
              {...formik.getFieldProps('email')}
              type='email'
              placeholder={t('forms.email')}
              isInvalid={formik.touched.email && formik.errors.email}
              isValid={formik.touched.email && !formik.errors.email}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className='mb-3' controlId='formPassword'>
            <Form.Label>{t('forms.senha')}</Form.Label>
            <PassWithEye formik={formik} />
          </Form.Group>
        </Row>
        <Form.Group>
          <Form.Check
            type='checkbox'
            id='Check1'
            className='custom-control custom-checkbox'
          >
            <Form.Check.Input
              {...formik.getFieldProps('remember')}
              className='custom-control-input'
              type='checkbox'
            />
            <Form.Check.Label className='custom-control-label' htmlFor='Check1'>
              {t('forms.lembrarsenha')}
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>
        {generalErrors && !formik.isValidating && !formik.isSubmitting && (
          <Alert variant='danger'>{generalErrors}</Alert>
        )}
        <Button
          disabled={formik.isValidating || formik.isSubmitting}
          loading={formik.isValidating || formik.isSubmitting}
          label={t('botoes.entrar')}
        />
        <ErrorFocus formikprm={formik} />
      </Form>
      <div className='social-login'>
        {(deviceType === 'mobile' && browserName === 'Facebook') ||
        browserName === 'Chrome WebView' ? (
          <Alert variant='warning' className='small'>
            {t('forms.msgsocialloginwebview')}
          </Alert>
        ) : (
          <>
            <div>{t('forms.sociallogin')}</div>
            <ButtonFacebook callback={responseFacebook} />
            <ButtonGoogle callback={responseGoogle} />
          </>
        )}
      </div>
      <div className='nao-membro'>
        <div>{t('forms.naomembro')}</div>
        {onClickPresignup ? (
          <a onClick={onClickPresignup} href='#'>
            {t('forms.linkcadastrar')}
          </a>
        ) : (
          <Link href='/presignup'>
            <a>{t('forms.linkcadastrar')}</a>
          </Link>
        )}
      </div>
      <hr />
      <div className='esqueci-senha'>
        <div>{t('forms.esquecisenha')}</div>
        <Link href='/passrecovery'>
          <a>{t('forms.esquecisenhalink')}</a>
        </Link>
      </div>
    </>
  )
}

export default LoginForm
